//import font
@import "./assets/css/fonts.scss";

:root {
  --header-text-color: #292d34;
  --main-text-color: #7c828d;
  --other-color-one: #7b68ee;
  --other-color-two: #fd71af;
  --other-color-three: #49ccf9;
  --other-color-four: #ffc800;
  --other-color-five: #00b884;
  --other-color-six: #fd7171;
  --color-black: #000;
}

.header {
  color: var(--main-text-color) !important;
  font-size: 50px;
  line-height: 69px;

  @media only screen and (max-width: 425px) {
    font-size: 35px;
  }
}

.text {
  color: var(--main-text-color) !important;
}

.d-inline-block {
  text-transform: capitalize;
}

*:not(i) {
  font-family: "Poppins", serif;
  font-style: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.body {
  background-color: #fafbfc;
}

.modal-backdrop {
  background-color: #161c2d;
  &.show {
    opacity: 0.8;
  }
}

.modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.modal-dialog {
  width: 100% !important;
}

svg {
  height: 30px;
  width: 30px;
  margin-right: 40px;
  transition: all 0.2s;
  fill: white;
  color: var(--other-color-one);
  &:hover {
    transform: scale(2);
  }
}

@media only screen and (max-width: 991px) {
  .navbar-light .navbar-toggler-icon {
    background: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::after,
    &::before {
      content: "";
      display: block;
      background-color: #55c57a;
      height: 4px;
      width: 100%;
      margin: 5px;
    }
  }
}
